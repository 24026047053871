
.image-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-popup-inner {
    position: relative;
  }
  
  .image-popup-inner img {
    max-width: 50%;
    max-height: 50%;
    margin-left: 25%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .transparent img {
    opacity: 0.5; /* Adjust opacity as needed */
  }
  
  .selected img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .close {
    position: absolute;
    top: 2%;
    right: 26%;
    background-color: transparent;
    color:white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    background-color: black;
  }

 

  @media (min-width: 768px) {
    .image-popup-inner img {
      max-width: 50%; /* Set maximum width for larger screens */
    }
  
    .close {
      right: 26%; /* Adjust the position of the close button for larger screens */
    }
  }
  
  

  