.operator{
    height: 300px;
    width: 800px;
    /* background: linear-gradient(250deg,rgb(224, 127, 192),rgb(224, 14, 154)); */
    background-color: #ffffff;
    margin-bottom: 50px;
    margin-left:300px ;
    /* border-top: 2px solid red; */
  box-shadow: 50px solid black;

}

.time{
    height: 50px;
    width: 120px;
    display: flex;
    background-color: #ffffff;
    text-align: center;
    justify-content: left;
    align-items: center;
    /* background: linear-gradient(250deg,rgb(224, 127, 192),rgb(224, 14, 154)); */
}

.machineoperator{
    height: 60px;
    /* width: 400px; */
    text-align: left;
    margin-top: 40px;
    margin-left: 50px;
    /* margin-bottom: 50px; */

    /* background-color:rebeccapurple ; */
}

.require{
    text-align: left;
   margin-left: 50px;
   margin-right: 50px;
   padding-bottom: 50px;
   
}

.article{
    /* margin-left: 50px; */
    /* background: linear-gradient(360deg,rgb(190, 216, 133),rgb(204, 214, 19)); */
    background-color: #ffffff;
    color: black  ;
    
}

.article:hover{
    background: rgb(81, 81, 225);

}












@media only screen and (max-width: 767px) {
    .img {
        flex-direction: column;
        align-items: center;
        margin: 1rem;
    }
  
    #img1 {
        margin-left: 0;
        margin-bottom: 10px;
    }
  
    .img-Container {
        margin-top: 20px;
    }
  
    .Container-content>h3 {
        padding-top: 10px;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .operator {
        width: 100%; /* Take up full width in mobile view */
        margin-left: 0;
    }

    .time {
        width: 100%;
    }

    .machineoperator,
    .require,
    .article {
        margin-left: 10px;
        margin-right: 10px;
    }
}