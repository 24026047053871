/* .about-image-area{
    margin-top: 5px;
}
@media only screen and (max-width: 767px) {
  .col-xl-12,
  .col-xl-200 {
    width: 100%; 
    margin-right: 0; 
    margin-left: 0; 
  }

  .thumbnail img {
    height: auto; 
  }

  .info {
    padding: 10px; /* Reduce padding for mobile 
  }
  .container{
    justify-content: center;
    align-items: center;
  }
}
*/
/*
@media screen and (max-width: 768px) {
  /* Adjust layout and styling for small screens here 
  .about-image-area {
    margin-bottom: 20px;
  }

  .work{
    width: 100%;
  }

  .info {
    width: '399px';
    /* padding: 20px; *
    padding-right: '20px';
    margin-right: '20px';
    border-radius: "3px";
    /* overflow-x: auto; */
    /* overflow-y: auto; 
  }
  .desc{
    /* width: 100%; *
    height: 200px;
    width: '398px';
    overflow: auto;
  
  }
  
} 
*/

@media screen and (max-width: 768px) {
  .about-image-area {
    margin-bottom: 20px; 
  }

  .work {
    width: inherit; 
  }

  .thumbnail img {
   
    max-width: 100%; 
    width: 410px;
    margin-left: -20px;
  }
  .thumbnail{
    /* margin-left: -5px; */
  }

  .info {
    width: 100%; 
    padding: 0px; 
    border-radius: 3px; 
    box-sizing: border-box; /* Include padding in width calculation */
    margin-right: '100px';
    overflow-y:auto ;
    text-align: justify;
    /* height: 100vh;
    -webkit-overflow-scrolling: touch; */
    
  }
}
