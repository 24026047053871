

.no-career{
  /* background-color: blueviolet ; */
  /* height: 300px; */
  padding-top: 450Px;
}


.career{
    /* color: red; */
    text-align: center;
   justify-content: center;
   /* text-align: center; */
  padding: 100px;
 /* margin-top: 50px; */
}

@media only screen and (max-width: 767px) {
  .img {
      flex-direction: column;
      align-items: center;
      margin: 1rem;
  }

  #img1 {
      margin-left: 0;
      margin-bottom: 10px;
  }

  .img-Container {
      margin-top: 20px;
  }

  .Container-content>h3 {
      padding-top: 10px;
  }
}
