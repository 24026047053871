/* 05.04 Header Logo CSS */

.header-logo {
    & a {
        position: relative;

        max-width: 225px;

        & img {
            width: 100%;
            padding: 15px 0;

            &.light-logo {
                position: absolute;
                top: 0;
                left: 0;

                opacity: 0;
            }

            &.dark-logo {
                width: 225px;
                // height: 125px;
            }
        }
    }

    @media #{$extra-small-mobile} {
        & a {
            max-width: 180px;
        }
        
        
    }
}

.header-light {
    & .header-logo {
        & a {
            & img {
                &.light-logo {
                    opacity: 1;
                }

                &.dark-logo {
                    opacity: 0;
                }
            }
        }
    }
}
@media #{$extra-small-mobile} {
    .ss {
        // max-width: 180px;
        width: 100px;
        height: 80px;

        &.dark-logo{
            height: 80px;
            width: 80px;

        }
    }
    

    
}