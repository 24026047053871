.faq-section {
    padding-bottom: 50px; /* Adjust as needed */
}

.faq-content {
    padding-right: 15px; /* Adjust as needed */
}

.faq-image-column {
    display: flex;
    align-items: center;
    justify-content: center;
}

.faq-image {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
}
@media (max-width: 768px) {
    .faq-section .row-cols-lg-2 {
      /* Stack columns vertically for smaller screens */
      flex-direction: column-reverse;
      width: 100%;
    }
    .faq-content{
      width: 420px;
      margin-left: 10%;
    }
  
    /* Adjust image size and layout */
    .faq-image-area img {
      height: 100%; /* Remove fixed height */
      width: 100%;
      margin-bottom: 20px; /* Add some spacing */
    }
    .faq-image-area{
      height: 100%;
      width: 280px ;
    }
    .col .mb-6 .ps-xl-12{
      width: 420px;
    }
  
    .faq-image-column {
      max-width: 100%;
      height: 100%;
      border-radius: 20px;
  }
  .faq-section .faq-image-area img {
    width: 100%;
    height: 100%;
    max-width: 100%;
  }
}

@media (min-width: 280px) and (max-width: 390px) {
  .faq-section .row-cols-lg-2 {
    /* Stack columns vertically for smaller screens */
    flex-direction: column-reverse;
    width: auto;
  }

  .faq-content {
    width:auto; /* Adjust width to fill the entire container */
    padding: 0 15px; /* Adjust padding as needed */
  }

  /* Adjust image size and layout */
  .faq-image-area img {
    height: auto; /* Remove fixed height */
    width: auto;
    border-radius: 20px;
    margin-bottom: 20px; /* Add some spacing */
  }

  .faq-image-area {
    width: auto; /* Adjust width to fill the entire container */
    height: auto; /* Remove fixed height */
  }
  
}



  

