.work {
    /* border: 1px solid #ccc; */
    border-radius: 3px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%; /* Ensure work items take full width of their container */
    max-width: 400px; /* Set maximum width for responsiveness */
    display: flex; /* Use flexbox to align thumbnail and info */
    flex-direction: column; /* Stack thumbnail and info vertically */
}

.thumbnailar {
    width: 380px;
    border: 1px solid #ccc; /* Add border between thumbnail and info */
    box-sizing: border-box;
    flex: 1; /* Make the thumbnail div flexible */
}

.thumbnailar img {
    width: 380px; /* Ensure images take full width of their container */
    height: 300px; /* Ensure images take full height of their container */
    object-fit: cover; /* Maintain aspect ratio and cover entire thumbnail */
}

.infor {
    width: 380px;
    height: 320px;
    border: 1px solid #ccc; 
    box-sizing: border-box;
    padding: 10px; /* Adjust padding as needed */
}


@media (max-width: 575.98px) {
    .work {
        width: 100%; /* Full width for extra small screens */
    }

    .thumbnailar {
        width: 100%; /* Adjusted to take full width */
    }

    .thumbnailar img {
        width: 100%; /* Adjusted for full width */
        height: auto; /* Adjusted for maintaining aspect ratio */
    }

    .infor {
        width: 100%; /* Adjusted to take full width */
    }
}

@media (max-width: 767px) {
    .work {
        width: 100%; /* Full width for mobile screens */
        margin-right: 0; /* Remove any right margin to prevent spacing issues */
    }

    .thumbnailar {
        width: 100%; /* Adjusted to take full width */
    }

    .thumbnailar img {
        width: 100%; /* Adjusted for full width */
        height: auto; /* Adjusted for maintaining aspect ratio */
    }

    .infor {
        width: 100%; /* Adjusted to take full width */
    }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .work {
        width: 48%; /* Two items per row on tablets */
    }

    .thumbnailar {
        width: 100%; /* Adjusted to take full width */
    }

    .thumbnailar img {
        width: 100%; /* Adjusted for full width */
        height: auto; /* Adjusted for maintaining aspect ratio */
    }

    .infor {
        width: 100%; /* Adjusted to take full width */
    }
}

@media (min-width: 1199px) and (max-width: 1400px) {
    .work {
        width: 30.33%; /* Three items per row on screens between 1199px and 1400px */
    }

    .thumbnailar {
        width: 100%; /* Adjusted to take full width */
    }

    .thumbnailar img {
        width: 100%; /* Adjusted for full width */
        height: auto; /* Adjusted for maintaining aspect ratio */
    }

    .infor {
        width: 100%; /* Adjusted to take full width */
    }
}

